/* @import url("./common//styles//add-popup-builder.css");
@import url("./common//styles/delete-user-popup.css");
@import url("./common//styles/user-toast.css"); */

body,
button,
div,
span,
.ant-btn,
li,
ul {
  margin: 0;
  font-family: "Be Vietnam Pro", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout {
  padding: 15px 15px 0px 15px;
}

.ant-layout.main-content {
  padding: 17px 0px 79px 0px;
}

.ant-table-column-sorter {
  display: none;
}

.outerheader {
  padding: 15px 15px 0 15px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: #f5f5f5;
  z-index: 1000;
}

.echarts-for-react div:nth-last-child(1) div div div:nth-last-child(2) {
  margin: 20px 0 0 !important;
}

.ant-layout-header {
  z-index: 1000;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 0px;
  line-height: unset;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-layout-footer {
  margin-left: 85px;
  position: fixed;
  bottom: 0;
  width: 91.5%;
  font-weight: 400;
  font-size: 16px;
  color: #888888;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 0px;
  margin-top: 20px;
}

.header-dropdown ul {
  padding: 0px !important;
}
.pick-dropdown {
  margin-top: -73px !important;
  padding: 0px !important;
  min-width: 160px;
}
.header-dropdown ul li {
  font-weight: 500 !important;
  font-size: 16px !important;
  text-align: center !important;
  color: #003c3c !important;
  padding: 8px 15px !important;
  background: #ffffff;
}
.header-dropdown ul li:last-child:hover a.sign-out {
  color: #003c3c;
}
.header-dropdown ul li a.sign-out {
  color: #ffffff;
}

.header-dropdown ul li:last-child {
  background: #f21167 !important;
  color: #fff !important;
}

.header-dropdown ul li:last-child:hover {
  background: #e0fbee !important;
  color: #003c3c !important;
}

.pick-dropdown .ant-select-item {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #444444 !important;
  padding: 10px 25px !important;
}

.common .ant-select-selection-item {
  font-size: 20px;
  color: #444444;
  font-weight: 700;
  padding-inline-start: 5px !important;
  margin: 0px 10px;
}
.common .ant-select-selection-item svg {
  padding-right: 5px;
}
.common .ant-select-selector {
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  color: rgb(68, 68, 68);
}

.common.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
}
.common .ant-select-arrow {
  inset-inline-start: 0px;
  font-size: 14px;
  color: #07a568;
}
.common .ant-select-arrow svg {
  height: 19px;
  width: 18px;
  backdrop-filter: blur(5px);
}

.common.arrow-right .ant-select-arrow {
  inset-inline-start: auto !important;
}

/* =================== Search box css start here =================== */
.search-dropdown-box {
  background-color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  margin-top: 10px;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border: 1px solid #d9d9d9ad;
  border-radius: 4px;
  /* border: 1px solid #d9d9d9; */
  /* position: absolute; */
  /* z-index: 1000; */
  /* width: 100%; */
}

.search-dropdown-box:empty {
  border: none;
}

.search-dropdown-row {
  cursor: pointer;
  text-align: start;
  margin: 10px;
}
/* =================== Search box css end here =================== */

/* =================== Table css start here =================== */
span.paginationVal {
  color: #07a568;
}

.ant-input-prefix {
  margin-inline-end: 10px !important;
}
.ant-btn:hover { 
  background-color: #07a568 !important;
}
/* =================== Table css end here =================== */